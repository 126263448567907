import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import Info from '../info';
import RangeCard from './rangeCard';
import Sides from './sides';

const RangeList = () => {
  const { t } = useI18next();
  return (
    <div className="bg-black lg:rounded-[0.5rem]">
      <Sides>
        <div className="pt-[1.25rem] pb-[5.625rem] lg:pt-[8.75rem] lg:pb-[8.75rem]">
          <Info
            title={t('High-performance data centres')}
            link={{
              link: '/about-us',
              text: t('About CR14'),
            }}
          >
            <p>
              <Trans>
                It provides tools that help to strengthen the security and
                performance of cyber-physical and IT systems of private
                companies and national and international organisations (such as
                NATO and the EU), leading to secure, trustworthy products and
                cyber-savvy personnel.
              </Trans>
            </p>
            <br />
            <p>
              <Trans>
                Cyber ranges are, in essence, high-performance data centres
                built to provide the basis for real-life scenario-based
                live-fire and table-top exercises for companies and national
                teams to test, validate and develop their cyber security
                products, readiness, and capability. It provides tools that help
                to strengthen the security and performance of cyber-physical and
                IT systems of private companies and national and international
                organisations (such as NATO and the EU), leading to secure,
                trustworthy products and cyber-savvy personnel.
              </Trans>
            </p>
          </Info>
          <RangeCard
            id="open-cyber-range"
            gradientFrom="from-open-cr"
            videoURL="/videos/video_OCR"
            posterURL="/images/OCR.png"
            title={t('Open Cyber Range')}
          >
            <div>
              <p>
                <Trans>
                  OCR is a virtual environment used for cybersecurity training
                  and technology testing and development. The CR14&apos;s OCR
                  platform will include tools and products that are not part of
                  standard cloud service.
                </Trans>
              </p>
              <br />
              <p>
                <Trans>
                  The OCR is a cyber range that aims to simplify the process of
                  creating cyber exercises as well as making it more cost
                  effective.
                </Trans>
              </p>
              <br />
              <p>
                <Trans>
                  In addition to providing a platform for cybersecurity
                  companies to develop, test and validate their innovative
                  products, it also creates a launchpad for new products to
                  emerge into the market and promotes security thinking and
                  cybersecurity educational activities, especially in the
                  private sector. OCR will also be one of the test bases for
                  NATO DIANA.
                </Trans>
              </p>
            </div>
          </RangeCard>
          <RangeCard
            id="estonian-cyber-range"
            gradientFrom="from-national-cr"
            reverse
            videoURL="/videos/video_ECR"
            posterURL="/images/ECR.png"
            title={t('Estonian Cyber Range')}
          >
            <div>
              <p>
                <Trans>
                  The Estonian national CR provides national and bilateral cyber
                  defence training and is the home base for the world&apos;s
                  largest international cyber exercises, such as Locked Shields
                  and Crossed Swords.
                </Trans>
              </p>
              <br />
              <p>
                <Trans>
                  In addition, CR14, as well as the NATO CCDCOE use this CR for
                  research and development. Since NATO&apos;s 2014 Wales Summit,
                  its infrastructure serves as the basis for creating the NATO
                  Cyber Range.
                </Trans>
              </p>
              <br />
              <p>
                <Trans>
                  The Estonian national CR is based on more than ten years of
                  operating experience, arguably being one of the earliest
                  establishments of its kind globally.
                </Trans>
              </p>
            </div>
          </RangeCard>
          <RangeCard
            id="nato-cyber-range"
            gradientFrom="from-nato-cr"
            videoURL="/videos/video_NCR"
            posterURL="/images/NCR.png"
            title={t('NATO Cyber Range')}
          >
            <div>
              <p>
                <Trans>
                  NATO CR is a platform and data centre enabling NATO to conduct
                  its largest cyber defence exercises and training, such as the
                  Cyber Coalition and the Coalition Warrior Interoperability
                  exercise (CWIX). CR14 is proud of the opportunity to serve the
                  development of NATO&apos;s capacity in this area since 2014
                  and to support Allies in achieving better preparedness for
                  cyber-attacks.
                </Trans>
              </p>
            </div>
          </RangeCard>
          <RangeCard
            id="classified-cyber-range"
            gradientFrom="from-classified-cr"
            reverse
            videoURL="/videos/video_CCR"
            posterURL="/images/CCR.png"
            title={t('Classified Cyber Range')}
          >
            <div>
              <p>
                <Trans>
                  Our mission is to explore the art of cybersecurity which might
                  sometimes extend beyond the limits of unclassified.
                </Trans>
              </p>
            </div>
          </RangeCard>
        </div>
      </Sides>
    </div>
  );
};

export default RangeList;
