import { Link } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

const Info = ({
  id,
  title,
  children,
  link,
  darkLine = false,
}: {
  id?: string;
  title: string;
  children: React.ReactNode;
  link?: {
    text: string;
    link: string;
  };
  darkLine?: boolean;
}) => {
  return (
    <div id={id}>
      <h2 className="lg:max-w-[40rem]">{title}</h2>
      <hr
        className={`mt-[1.25rem] lg:mt-[1.875rem] ${
          darkLine ? 'border-black' : 'opacity-40'
        }`}
      />
      <div className="mt-[1.25rem] lg:mt-[1.875rem] lg:flex lg:flex-row-reverse">
        <div className="lg:max-w-[40rem]">
          {children}
          {link && (
            <Link
              to={link.link}
              className="mt-[1.25rem] lg:mt-[1.875rem] button font-semibold"
            >
              {link.text}
            </Link>
          )}
        </div>
        <div />
      </div>
    </div>
  );
};

export default Info;
