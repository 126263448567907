import * as React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import Sides from './sides';

const Splash = () => {
  return (
    <div className='bg-cover bg-[url("../images/ranges-mobile-landing.png")] lg:bg-[url("../images/ranges-desktop-landing.webp")] lg:rounded-lg'>
      <div className="pt-[6.375rem] lg:pt-[3.575rem] pb-[2.5rem] lg:pb-[3.575rem] lg:rounded-lg full-filter lg:half-filter">
        <Sides>
          <h1>
            <Trans>Cyber ranges</Trans>
          </h1>
          <p className="mt-[5.125rem] lg:mt-[7.8rem] lg:max-w-[40rem] text-base lg:text-xl">
            <Trans>
              Cyber Range is a unique software-based virtual environment used
              for cybersecurity training and the development of dual-use
              innovative technologies using cyberspace.
            </Trans>
          </p>
        </Sides>
      </div>
    </div>
  );
};

export default Splash;
