import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Splash from '../components/ranges/splash';
import Base from '../components/base';
import RangeList from '../components/ranges/rangeList';
import SEO from '../components/seo';

const Ranges = ({ location }: PageProps<Queries.RangesQuery>) => (
  <Base location={location}>
    <Splash />
    <RangeList />
  </Base>
);

export default Ranges;

export const pageQuery = graphql`
  query Ranges($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="CR14 Ranges" />;
