import * as React from 'react';

const RangeCard = ({
  id,
  title,
  videoURL,
  posterURL,
  children,
  reverse,
  gradientFrom,
}: {
  id: string;
  title: string;
  videoURL: string;
  posterURL: string;
  gradientFrom: string;
  children: React.ReactNode;
  reverse?: boolean;
}) => {
  return (
    <div id={id} className="mt-[5.625rem] lg:mt-[8.75rem]">
      <div
        className={`card flex flex-col lg:flex-row${
          reverse ? '-reverse' : ''
        } justify-between items-stretch gap-[1.875rem] lg:gap-[3.75rem]`}
      >
        <div className="flex flex-col justify-between basis-1/2">
          <h2 className="mb-8 lg:mb-12">{title}</h2>
          {children}
        </div>
        <hr className="lg:vertical lg:h-auto opacity-40" />
        <div className="lg:basis-1/2 aspect-square overflow-hidden flex flex-col justify-center">
          <div className="flex justify-center lg:block aspect-square overflow-hidden relative">
            <div
              className={`absolute rounded-[50%] rotate-180 z-30 w-full h-full bg-gradient-to-b to-transparent ${gradientFrom} overflow-hidden`}
            />
            <div className="absolute w-full z-20 h-full bg-transparent rounded-[50%] rotate-180 overflow-hidden">
              <video poster={posterURL} autoPlay muted loop playsInline>
                <source
                  src={`${videoURL}.webm`}
                  type="video/webm; codecs=vp9"
                />
                <source src={`${videoURL}.mp4`} type="video/mp4; codecs=hevc" />
                <source src={`${videoURL}.m4v`} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeCard;
